<template>
    <div>
        <div class="container">
            <div class="d-flex align-center flex-column">
                <div class="col-L">
                    <div class="d-flex align-center">
                        <div class="font-5">
                            <v-avatar size="50" color="white">
                                <img src="../assets/logo_sahel.png" alt="alt" class="mr-6" />
                            </v-avatar>
                            <span class="sahel-assistance">SAHEL ASSISTANCE</span>
                        </div>
                    </div>
                    <h2>Nous effectuons actuellement une maintenance planifiée.</h2>
                    <hr />
                    <p>
                        Nous espérons être de retour en ligne très prochainement. Merci de
                        votre patience et nous nous excusons pour tout inconvénient causé.
                    </p>
                </div>
                <div class="col-R">
                    <svg x="0px" y="0px" viewBox="0 0 219.9 219.9" style="enable-background:new 0 0 219.9 219.9;">
                        <!-- SVG content here -->
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Maintenance',
    async mounted() {
        await this.getMaintenance();
    },
    methods: {
        async getMaintenance() {
            try {
                const response = await this.$http.get('auth/check-maintenance');
                if (response.data.maintenance) {
                    this.$router.push('/maintenance');
                } else {
                    this.$router.push('/');
                }
            } catch {
                this.$router.push('/maintenance');
            }
        }
    }
};
</script>

<style scoped>
body {
    background-color: #f5f5f5;
    font-family: 'Montserrat', sans-serif;
}

.d-flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.container {
    align-items: center;
    max-width: 90%;
}

.col-L {
    padding: 2em;
}

.col-R {
    max-width: 50%;
    width: 50%;
}

.sahel-assistance {
    margin-left: 10px;
    font-size: 28px;
    color: rgb(85, 179, 239);
    font-weight: bolder;
}

.st0 {
    fill: #00a19a;
}

.st1 {
    fill: #ffb8b8;
}

.st2,
.st4,
.st7 {
    stroke: #3f0e8c;
    stroke-miterlimit: 10;
}

.st2 {
    fill: none;
}

.st3 {
    fill: #ffffff;
    stroke: #1d1d1b;
    stroke-width: 0.9847;
    stroke-miterlimit: 10;
}

.st4 {
    fill: #00d292;
}

.st5 {
    fill: #00d292;
}

.st6 {
    fill: #00cccb;
}

.st7 {
    fill: #00cccb;
}

.st8 {
    fill: none;
    stroke: #1d1d1b;
    stroke-width: 0.9847;
    stroke-miterlimit: 10;
}

.st4,
.st5,
.st6 {
    animation: downMotion 8s ease-in-out infinite;
}

.st0 {
    animation: upMotion 10s ease-in-out infinite;
}

.st7,
.run {
    animation: upMotion 6s ease-in-out infinite;
}

.rotate {
    transform-origin: 116px 88px;
    animation: 4s rotate infinite linear;
}

@keyframes downMotion {
    0% {
        transform: translate(0, -15px);
    }
    50% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, -15px);
    }
}

@keyframes upMotion {
    0% {
        transform: translate(0, 15px);
    }
    50% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, 15px);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 992px) {
    .container {
        justify-content: center;
        height: calc(100vh - 16px);
        display: flex;
        flex-wrap: wrap;
    }

    .col-L {
        width: 40%;
        margin-right: 10%;
    }
}

@media only screen and (max-width: 991px) {
    .flex-column {
        flex-direction: column;
    }
}
</style>
