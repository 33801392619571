// axios-interceptors.js

import axios from 'axios';
import Auth from '../Auth/auth';
import router from '../router'
const auth = new Auth();

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.localStorage.setItem('session_expired', true);
      window.localStorage.setItem('last_route', router.history._startLocation)
      // Le jeton d'accès a expiré, renouvelez-le ici.
      return renewTokenAndRetryRequest(error);
    } else if (error.response.status === 503) {
      // Rediriger vers la page de maintenance
      router.push({ name: 'maintenance' });
    }
    return Promise.reject(error);
  }
);

async function renewTokenAndRetryRequest(error) {
  if(error) {
    if(auth.check() && router.currentRoute.name !== 'session.expired') {
      router.push({ name: 'session.expired'})
    }
  }
}
