import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Welcome from "../views/Welcome.vue";
import Auth from "../Auth/auth";
import NotFound from "../views/NotFound.vue";
import MedecinsSpecialite from "../views/Medecins";
import MedecinShow from "../views/MedecinShow.vue";
import PriveRendezVous from "../views/PriveRendezVous.vue";
import MedcinRegister from "../views/medcin/MedcinRegister.vue"; 
import IntervenantRegister from "../views/intervenant/IntervenantRegister.vue";
import Relence from "../views/patient/Relence.vue";
import ForgotPassword from "../views/ForgetPassword.vue";
import PasswordReset from "../views/ResetPassord.vue";
import DashboardPatient from "../views/patient/DashboardPatient.vue";
import ConditionUtilisationPage from "../views/ConditionUtilisationPage.vue"
import { HomeRoom } from "../views/doctor/room";
import {
  AppointmentDetailDoctor,
  AppointmentsDoctor,
  AssignConsulFormDoctor,
  AssignTypeFormDoctor,
  ConsultationsDoctor,
  CreateVisitMedicalTypeDoctor,
  DashbordDoctor,
  HomeDoctor,
  HomeVisitMedicalTypeDoctor,
  ListPatients,
  NotificationsDoctors,
  ParametreDoctor,
  PatientAppointment,
  PatientConsultations,
  PatientDetail,
  PatientFiles,
  PatientMedicalVisits,
  PatientsDoctor,
  SecretaireDoctor,
  SharedFiles,
  VisitMedicalDoctor,
  ProfilDoctor
} from "../views/doctor";
import {
  ComptePatient,
  DocumentsPatient,
  NotificationsPatient,
  ProchesPatient,
  RendezVousDetailPatient,
  RendezVousPatient,
  RoomWaitingPatient,
 
} from "../views/patient";
import {
  DashboardAdmin,
  HomeAdmin,
  LoginAdmin,
  UsersAdmin,
  DepartmentsAdmin,
  NotificationAdmin,
  ProfileDoctorForAdmin,
  ProfilePatientAdmin,
  ListUsers,
  ProfileIntervenantAdmin,
  AdminProfile,
  MoyensPaiement,
  SecteteurDactivite,
  AdminFormulaires,
  CreateFormeAdmine,
  PlanAbonnement,
  PayementsAdmin,
  ListePayements,
  DetailPayement,
  Entreprises,
  CreateEntreprise,
  EntrePriseHome,
} from "../views/admin";
import {
  Consultations,
  ConsultationUpdate,
  PatientConsultationCreate,
  ViewConsultationPatient,
} from "../views/doctor/consultations/index";
import {
  PatientVisitMedicalCreate,
  UpdateMedicalVisit,
  ViewMedicalVisitDetail,
  VisitMedicals,
} from "../views/doctor/visitMedicals";
import { FormsDoctor, InfosDoctor } from "../components/doctor";
import { FieldForm, HomeForm } from "../components/formulaire";
import {
  BuelletinPrinter,
  CerticatConsultation,
  CerticatMedicalVisit,
  ConsultationPrinter,
  FacturePrinter,
  HomePrinter,
  MedicalVisitPrinter,
  OrdonnacePrinter,
  ReposMedicalPrinter,
} from "../views/printers";
import {
  CompteSecretaire,
  DashboardSecretaire,
  Factures,
} from "../views/secretaire";


import HomeTelemedecine from "../components/telemedecine/JoinRoom.vue"
import CallTelemedecine from "../components/telemedecine/call.vue"
import RendezRVTelemedecine from "../views/RendezVousTelemedecine.vue"
import TelemedecineSansRV from "../views/TelemedecineSansRV.vue"
import { TestAbonnement } from "../views/abonnement";
import MedecinsRandom from '../views/MedecinsRandom.vue'
import { PaytechCancel, PaytechSuccess } from "../views/paytech";
import { CompteIntervenant, DashboardIntervenant, PatientsIntervenant, RendezVousIntervenant ,DocumentsIntervenant} from "../views/intervenant";
import MedecinsTeleexpertise from "../views/MedecinsTeleexpertise.vue"
import StepTeleconsultationDirect from "../views/StepTeleconsultationDirect.vue"
import { 
  CabinetParametre, 
  CareersParametre, 
  ConditionPayParametre, 
  HomeParametre, 
  IndisponibiliteDate, 
  LanguesParametre, 
  LocalisationParametre, 
  LogoParametre, 
  MotifsParametre, 
  NavigateParametre, 
  Presentation, 
  SignatureParametre, 
  TeleconsultationParametre, 
  TeleexpertiseParametre 
} from "../views/doctor/parametres";

import SessionExpire from '../views/SessionExpire.vue'
import { EntrepriseDashboard, HomeEntreprises, ParametreEntreprise, PatientsEntreprises, ShowPatientEntreprise } from "../views/entreprise";
import { HomeLogs, IndexLogs, UsersMonthLogs, UsersNotConnectedLogs, UsersWeekLogs } from "../views/admin/logs/index";
import Maintenance from '../views/maintenance.vue'
Vue.use(VueRouter);

window.Event = new Vue();
const auth = new Auth();
const routes = [
//public routes
    {
      path: "",
      component: Welcome,
      children: [
        {
          path: "/",
          component: Home,
          name: "home",
        },
      ],
    },
    
    {
      path: '/abonnement',
      component: TestAbonnement,
      name: 'abonnement ',
      props: route => ({ query: route.query.q })
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance
    },
    {
      path: '/rendez-vous/:sessionId/telemedecine',
      component: RendezRVTelemedecine,
      name: 'telemedecine.join'
    },
  
    {
      path: '/join-room',
      component: HomeTelemedecine,
      name: 'join.home'
    },
    {
      path: '/telemedecine/:sessionId/:name',
      name: 'join.meet',
      component: CallTelemedecine,
    },
    {
      path: "/sahel-rdv/admin/login",
      component: LoginAdmin,
      name: "admin.login",
    },
    {
      path:'/conditions-generales',
      component: ConditionUtilisationPage,
      name:'condiction.utilisation'
    },
    {
      path: '/reset/password',
      component: ForgotPassword,
      name: "forgot.password",
    },
    {
      path: "/reset/password/:token",
      component: PasswordReset,
      name: "reset.password",
    },
    {
      path: "/medecin/:id/show",
      component: MedecinShow,
      name: "medecin.show",
    },
    {
      path: "/medecin/:id/prise-rv",
      component: PriveRendezVous,
      name: "medecin.rendez-vous",
    },
    {
      path: "/medecins",
      component: MedecinsSpecialite,
      name: "medecins.specialite",
      props: route => ({ query: route.query.q })
    },
    {
      path: '/telemedecine-sans-rv',
      component: TelemedecineSansRV,
      name:'telemedecine.sans.rv'
    },
    {
      path: '/rendez-vous/:type',
      component: MedecinsRandom,
      name: 'medecins.rv'

    },
    {
      path: '/medecins/teleexpertise',
      component: MedecinsTeleexpertise,
      name: 'medecins.teleexpertise'

    },
//Paytech paiement
    {
      path: '/paytech/paiement/:id/sucsess',
      component: PaytechSuccess,
      meta: { middlewareAuth: true }
    },
    {
      path: '/paytech/paiement/:id/cancel',
      component: PaytechCancel,
      meta: { middlewareAuth: true }
    },
    {
      path: '/telemedecine-direct/:room/step',
      component: StepTeleconsultationDirect,
      name: 'teleconsultation.direct.step'

    },
    {
      path: '/session-expire',
      component: SessionExpire,
      name: 'session.expired',
      //props: route => ({ token: route.query.q })
    },
  // Patient dashboard
    {
      path: "/dashboard/patient",
      component: DashboardPatient,
      meta: { middlewarePatient: true },
      redirect: { name: "patient.home" },

      children: [
        {
          path: "mon-compte",
          name: "patient.home",
          component: ComptePatient,
        },
        {
          path: "mes-proches",
          name: "compte.mes-proches",
          component: ProchesPatient,
        },
        {
          path: "mes-rendez-vous",
          name: "compte.mes-rendez-vous",
          component: RendezVousPatient,
        },
        {
          path: "mes-documents",
          name: "compte.documents",
          component: DocumentsPatient,
        },
        {
          path: "notifications",
          name: "patient.notify",
          component: NotificationsPatient,
        },
        {
          path: "rendez-vous/:appointment/detail",
          name: "compte.appointment.detail",
          component: RendezVousDetailPatient,
        },
        {
          path: 'room-waiting',
          component: RoomWaitingPatient,
          name: 'patient.room.waitings'
        }
      ],
    },
 // Intervenant dashboard
    {
      path: "/dashboard/intervenant",
      component: DashboardIntervenant,
      meta: { middlewareIntervenant: true },
      redirect: { name: "intervenant.home" },

      children: [
        {
          path: "mon-compte",
          name: "intervenant.home",
          component: CompteIntervenant,
        },
        
        {
          path: "mes-rendez-vous",
          name: "intervenant.mes-rendez-vous",
          component: RendezVousIntervenant,
        },
        {
          path: "documents",
          name: "intervenant.documents",
          component: DocumentsIntervenant,
        },
        {
          path: "notifications",
          name: "intervenant.notify",
          component: NotificationsPatient,
        },
        
        {
          path: 'room-waiting',
          component: RoomWaitingPatient,
          name: 'intervenant.room.waitings'
        },
        {
          path: 'patients',
          component: PatientsIntervenant,
          name: 'intervenant.patients'
        },
        {
          path: "rendez-vous/:appointment/detail",
          name: "intervenant.appointment.detail",
          component: RendezVousDetailPatient,
        },
      ],
    },

// Doctor routes
    {
      path: "/dashboard/doctor",
      component: DashbordDoctor,
      meta: { middlewareDoctor: true },
      redirect: { name: "doctor.home" },
      children: [
        {
          path: "home",
          component: HomeDoctor,
          name: "doctor.home",
        },
        {
          path: "profil",
          component: ProfilDoctor,
          name: "doctor.profil"
        },
        {
          path: "patients",
          component: PatientsDoctor,
          redirect: { name: "doctor.patients" },

          children: [
            {
              path: "",
              component: ListPatients,
              name: "doctor.patients",
            },
            {
              path: ":auteur/:patient",
              component: PatientDetail,
              name: "doctor.patient.detail",
              redirect: { name: "doctor.patient.consultations" },
              children: [
                {
                  path: "consultations",
                  component: PatientConsultations,
                  name: "doctor.patient.consultations",
                },
                {
                  path: "visite-medicale",
                  component: PatientMedicalVisits,
                  name: "doctor.patient.medical.visits",
                },
                {
                  path: "rendez-vous",
                  component: PatientAppointment,
                  name: "doctor.patient.appointments",
                },
                {
                  path: "fichier",
                  component: PatientFiles,
                  name: "doctor.patient.files",
                },
              ],
            },
          ],
        },
        {
          path: "rendez-vous",
          component: AppointmentsDoctor,
          name: "doctor.appointments",
        },
        {
          path: "rendez-vous/:appointment/detail",
          component: AppointmentDetailDoctor,
          name: "doctor.appointment.detail",
        },
        //consultations
          {
            path: "consultations",
            component: ConsultationsDoctor,
            redirect: { name: "doctor.consultations" },
            //name: 'doctor.consultations',
            children: [
              {
                path: "",
                component: Consultations,
                name: "doctor.consultations",
              },
              {
                path: "view/:consultation",
                component: ViewConsultationPatient,
                name: "doctor.consultation.view",
              },
              {
                path: "update/:consultation",
                component: ConsultationUpdate,
                name: "doctor.consultation.update",
              },
              {
                path: ":auteur/:patient/create",
                component: PatientConsultationCreate,
                name: "doctor.consultation.patient.create",
                //props: route => ({ patient: route.patient.auteur })
              },
            ],
          },
          {
            path: "visite-medicales",
            component: VisitMedicalDoctor,
            redirect: { name: "doctor.visite-medicales" },
            children: [
              {
                path: "",
                component: VisitMedicals,
                name: "doctor.visite-medicales",
              },
              {
                path: "view/:medical_visit_id",
                component: ViewMedicalVisitDetail,
                name: "doctor.visit.medical.view",
              },
              {
                path: "update/:medical_visit_id",
                component: UpdateMedicalVisit,
                name: "doctor.visit.medical.update",
              },
              {
                path: ":auteur/:patient/create",
                component: PatientVisitMedicalCreate,
                name: "doctor.visit.medical.patient.create",
              },
            ],
          },
          {
            path: "mes-parametres",
            component: ParametreDoctor,
            redirect: { name: "doctor.parametre.infos" },
            name: "doctor.parametre",
            children: [
              {
                path: "informations",
                component: NavigateParametre,
                redirect: { name: "doctor.parametre.infos"},
                children: [
                  {
                    path: "",
                    component: InfosDoctor,
                    name: "doctor.parametre.infos",
                  },
                  {
                    path: 'view',
                    component: HomeParametre,
                    redirect: { name: 'doctor.parametre.infos.cabient' },
                    children: [
                      {
                        path: "cabinet",
                        component: CabinetParametre,
                        name: 'doctor.parametre.infos.cabinet',
                      },
                      {
                        path: 'teleconsultation',
                        component: TeleconsultationParametre,
                        name: 'doctor.parametre.infos.teleconsultation'
                      },
                      {
                        path: 'teleexpertise',
                        component: TeleexpertiseParametre,
                        name: 'doctor.parametre.infos.teleexpertise'
                      },
                      {
                        path: 'logo',
                        component: LogoParametre,
                        name: 'doctor.parametre.infos.logo'
                      },
                      {
                        path: 'signature',
                        component: SignatureParametre,
                        name: 'doctor.parametre.infos.signature'
                      },
                      {
                        path: 'langues',
                        component: LanguesParametre,
                        name: 'doctor.parametre.infos.langues'
                      },
                      {
                        path: 'motifs',
                        component: MotifsParametre,
                        name: 'doctor.parametre.infos.motifs'
                      },
                      {
                        path: 'careers',
                        component: CareersParametre,
                        name: 'doctor.parametre.infos.careers'
                      },
                      {
                        path: 'localisation',
                        component: LocalisationParametre,
                        name: 'doctor.parametre.infos.localisation'
                      },
                      {
                        path: 'condition-pay',
                        component: ConditionPayParametre,
                        name: 'doctor.parametre.infos.condition.pay'
                      },
                      {
                        path: 'presentation',
                        component: Presentation,
                        name: 'doctor.parametre.infos.presentation'
                      },
                      {
                        path: 'indisponibilite-date',
                        component: IndisponibiliteDate,
                        name: 'doctor.parametre.infos.indisponobilite.date'
                      }
                    ]
                  },
                  
                ]
              },
              {
                path: "consultations",
                component: AssignConsulFormDoctor,
                name: "doctor.parametre.consultation",
              },
              {
                path: "type-visit-medical",
                component: HomeVisitMedicalTypeDoctor,
                name: "doctor.parametre.visit.medical",
                redirect: { name: "doctor.parametre.visit.medical.create" },
                children: [
                  {
                    path: "type-visit-medical/create-type",
                    component: CreateVisitMedicalTypeDoctor,
                    name: "doctor.parametre.visit.medical.create",
                  },
                  {
                    path: "type-visit-medical/:id/ajout-de-formulaire",
                    component: AssignTypeFormDoctor,
                    name: "doctor.parametre.visit.medical.assign.form",
                  },
                ],
              },
              {
                path: "formulaire",
                component: FormsDoctor,
                redirect: { name: "doctor.create.form" },
                name: "doctor.parametre.forms",
                children: [
                  {
                    path: "create-form",
                    component: HomeForm,
                    name: "doctor.create.form",
                  },
                  {
                    path: ":id/ajout-champs",
                    component: FieldForm,
                    name: "doctor.create.fields.form",
                  },
                ],
              },
            ],
          },
          {
            path: "secretaria",
            component: SecretaireDoctor,
            name: "doctor.secretaria",
          },
          {
            path: "notifications",
            component: NotificationsDoctors,
            name: "doctor.notify",
          },
          {
            path: 'room-telemedecine',
            component: HomeRoom,
            name: 'doctor.room'
          },
          {
            path: 'shared-files',
            component: SharedFiles,
            name: 'doctor.shared.files'
          }
      ],
    },
// Admin Dashbord
    {
      path: "/dashboard/admin",
      component: DashboardAdmin,
      meta: { middlewareAdmin: true },
      redirect: { name: "admin.home" },
      children: [
        {
          path: "home",
          component: HomeAdmin,
          name: "admin.home",
        },
        {
          path: "users",
          component: UsersAdmin,
          name: 'admin.users',
          redirect: { name: 'admin.admins'},
          children: [
            {
              path: 'admin',
              component: ListUsers,
              name: 'admin.admins'
            },
            {
              path: 'medecin',
              component: ListUsers,
              name: 'admin.doctors'
            },
            {
              path: 'patient',
              component: ListUsers,
              name: 'admin.patients'
            }
          ]
          //name: "admin.user",
        },
        {
          path: "users/:id",
          component: ProfileDoctorForAdmin,
          name: "admin.userProfile",
        },
        {
          path: "departments-list",
          component: DepartmentsAdmin,
          name: "admin.departments",
        },
        {
          path: "notifications",
          component: NotificationAdmin,
          name: "admin.notify",
        },
        {
          path: "logs",
          component:IndexLogs,
          name: 'admin.logs',
          redirect: { name: 'admin.logs.home'},
          children: [
            {
              path: "",
              component: HomeLogs,
              name: 'admin.logs.home'

            },
            {
              path: "week",
              component: UsersWeekLogs,
              name: 'admin.users.logs.week'
            },
            {
              path: "month",
              component: UsersMonthLogs,
              name: 'admin.users.logs.month'
            },
            {
              path: "not-connected",
              component: UsersNotConnectedLogs,
              name: "admin.users.not-connected.logs"
            }
          ]

        },
        // {
        //   path: "lire-notification/:id",
        //   component: NotificationlistAdmin,
        //   name: "admin.notificationLis",
        // },
        {
          path: 'patient/:id',
          component: ProfilePatientAdmin,
          name: 'admin.patientProfile'
        },
        {
          path: 'intervenant/:id',
          component: ProfileIntervenantAdmin, 
          name: 'admin.IntervenantProfile'
        },
        

      {
        path: 'adminProfile',
        component: AdminProfile,
        name: 'admin.profileAdmin'
      },
      {
        path: 'adminMoyenPaiement',
        component: MoyensPaiement,
        name: 'admin.moyenPaiement'
      },
      {
        path: 'adminSecteteurDactivite',
        component: SecteteurDactivite,
        name: 'admin.SecteteurDactivite'
      },
      {
        path: "formulaire",
        component: FormsDoctor,
        redirect: { name: "doctor.create.form" },
        name: "admin.parametre.forms",
        children: [
          {
            path: "create-form",
            component: HomeForm,
            name: "admin.create.form",
          },
          {
            path: ":id/ajout-champs",
            component: FieldForm,
            name: "admin.create.fields.form",
          },
        ],
      },
      {
        path: "mes-formulaires",
        component: AdminFormulaires,
        redirect: { name: "form.admin" },
        name: "admin.formulaires",
        children: [
          {
            path: "informations",
            component: CreateFormeAdmine,
            name: "form.admin", 
          },
          {
            path: "consultations",
            component: AssignConsulFormDoctor,
            name: "admin.parametre.consultation",
          },
          {
            path: "type-visit-medical",
            component: HomeVisitMedicalTypeDoctor,
            name: "admin.parametre.visit.medical",
            redirect: { name: "admin.parametre.visit.medical.create" },
            children: [
              {
                path: "type-visit-medical/create-type",
                component: CreateVisitMedicalTypeDoctor,
                name: "admin.parametre.visit.medical.create",
              },
              {
                path: "type-visit-medical/:id/ajout-de-formulaire",
                component: AssignTypeFormDoctor,
                name: "admin.parametre.visit.medical.assign.form",
              },
            ],
          },
          // {
          //   path: "formulaire",
          //   component: FormsDoctor,
          //   redirect: { name: "admin.create.form" },
          //   name: "admin.parametre.forms",
          //   children: [
          //     {
          //       path: "create-form",
          //       component: HomeForm,
          //       name: "admin.create.form",
          //     },
          //     {
          //       path: ":id/ajout-champs",
          //       component: FieldForm,
          //       name: "admin.create.fields.form",
          //     },
          //   ],
          // },
        ],
      },
      {
        path: 'mes-plans-abonnements',
        component: PlanAbonnement,
        name: 'admin.plans.abonnements'

      },
      {
        path: 'payements',
        component: PayementsAdmin,
        name: 'admin.payements',
        redirect: {name: 'admin.liste.payements'},
        children: [
          {
            path: 'liste',
            component: ListePayements,
            name: 'admin.liste.payements'

          },
          {
            path: 'detail/:id/payement',
            component: DetailPayement,
            name: 'admin.detail.payement'
          }
        ]
      },
      {
        path: 'entreprises',
        component: EntrePriseHome,
        name: 'admin.entreprises',
        redirect: { name: 'admin.entreprise.home'},
        children: [
          {
            path: '',
            component: Entreprises,
            name: 'admin.entreprise.home'
          },
          {
            path: 'create',
            component: CreateEntreprise,
            name: 'admin.entreprise.create'
          }
        ]
      }
      
    ],
  },
  // Secretaire dashboard
    {
      path: "/dashboard/secretaire",
      component: DashboardSecretaire,
      redirect: { name: "secretaire.home" },
      meta: { middlewareSecretaire: true },
      children: [
        {
          path: "home",
          component: HomeDoctor,
          name: "secretaire.home",
        },
        {
          path: "compte",
          component: CompteSecretaire,
          name: "secretaire.compte",
        },
        {
          path: "rendez-vous",
          component: AppointmentsDoctor,
          name: "secretaire.appointments",
        },
        {
          path: "rendez-vous/:appointment/detail",
          component: AppointmentDetailDoctor,
          name: "secretaire.appointment.detail",
        },
        {
          path: "factures",
          component: Factures,
          name: "secretaire.factures",
        },
        {
          path: "patients",
          component: PatientsDoctor,
          children: [
            {
              path: "",
              component: ListPatients,
              name: "secretaire.patients",
            },
            {
              path: "view/:consultation",
              component: ViewConsultationPatient,
              name: "secretaire.consultation.view",
            },
            {
              path: "view/:medical_visit_id",
              component: ViewMedicalVisitDetail,
              name: "secretaire.medical.visit.view",
            },
            {
              path: ":auteur/:patient",
              component: PatientDetail,
              name: "secretaire.patient.detail",
              redirect: { name: "secretaire.patient.consultations" },
              children: [
                {
                  path: "consultations",
                  component: PatientConsultations,
                  name: "secretaire.patient.consultations",
                },
                {
                  path: "visite-medicale",
                  component: PatientMedicalVisits,
                  name: "secretaire.patient.medical.visits",
                },
                {
                  path: "rendez-vous",
                  component: PatientAppointment,
                  name: "secretaire.patient.appointments",
                },
              ],
            },
          ],
        },
      ],
    },
  // Doctor register
    {
      path: "/doctor-register",
      name: "doctor.register",
      //meta: {middlewarePublic: true},
      component: MedcinRegister,
    },
  // Entreprise dashboard
    {
      path: '/dashboard/entreprise',
      component: EntrepriseDashboard,
      name: 'entreprise.dashboard',
      redirect: { name: 'entreprise.home' },
      meta: { middlewareEntreprise: true },
      children: [
        {
          path: 'home',
          component: HomeEntreprises,
          name: 'entreprise.home',
        },
        {
          path: 'patients',
          component: PatientsEntreprises,
          name: 'entreprise.patients',
        },
        {
          path: 'parametre',
          component: ParametreEntreprise,
          name: 'entreprise.parametre'
        },
        {
          path: ':id/patient',
          component: ShowPatientEntreprise,
          name: 'entreprise.patient.detail'

        }
      ]

    },
    {
      path: "/intervenant-register",
      name: "intervenant.register",
      //meta: {middlewarePublic: true},
      component: IntervenantRegister,
    },
    {
      path: "/patient/relence/:id",
      name: "patient.relence",
      component: Relence,
    },
  //Printer routes
    {
      path: "/printer",
      component: HomePrinter,
      children: [
        {
          path: "consultation/:consultation",
          component: ConsultationPrinter,
        },
        {
          path: "consultation/certicat/:consultation",
          component: CerticatConsultation,
        },
        {
          path: "medical-visit/:medical_visit",
          component: MedicalVisitPrinter,
        },
        {
          path: "medical-visit/certificat/:medical_visit",
          component: CerticatMedicalVisit,
        },
        {
          path: "bulletin/:id",
          component: BuelletinPrinter,
        },
        {
          path: "ordonnance/:type/:id",
          component: OrdonnacePrinter,
        },
        {
          path: "facture/:type/:id",
          component: FacturePrinter,
        },
        {
          path: "repos-medical/:id",
          component: ReposMedicalPrinter,
        },
      ],
    },
  //Not Found
    {
      path: "*",
      name: "not-found",
      component: NotFound,
    },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    //https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if (to.hash) {
            return { selector: to.hash }
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 }
        }
  }
});

router.beforeEach((to, from, next) => {
  // Middleware Auth
  if (to.matched.some((record) => record.meta.middlewareAuth)) {
    if (!auth.check()) {
      next({
        name: 'home'
      });
      return;
    }
  }
  // Middleware public
  // if(to.matched.some((record)=> record.meta.middlewarePublic)) {
  //   if(auth.check) {
  //     next({
  //       name: 'home'
  //     });
  //     return;
  //   }
  // }
  // Middleware Patient
    if (to.matched.some((record) => record.meta.middlewarePatient)) {
        if (!auth.user.is_patient) {

            if (auth.user.is_doctor) {
                next({
                    name: "doctor.home",
                });
            }
            else if (auth.user.is_admin) {
                next({
                    name: "admin.home",
                });
            } 
            else if (auth.user.is_secretaire) {
                next({
                    name: "secretaire.home",
                });
            } 
            else if(auth.user.is_intervenant) {
              next({
                name: "intervenant.home"
              });
            }
            else {
                next({
                    name: 'home'
                });
            }

            return;
        }
    }
  // Middleware Doctor
    if (to.matched.some((record) => record.meta.middlewareDoctor)) {
        if (!auth.user.is_doctor) {

            if (auth.user.is_patient) {
                next({
                    name: "patient.home",
                });
            } else if (auth.user.is_admin) {
                next({
                    name: "admin.home",
                });
            } else if (auth.user.is_secretaire) {
                next({
                    name: "secretaire.home",
                });
            } else if(auth.user.is_intervenant) {
              next({
                name: "intervenant.home"
              });
            } else {
                next({
                    name: 'home',
                });
            }
            return;
        }
    }
  // Middleware Secretaire
    if (to.matched.some((record) => record.meta.middlewareSecretaire)) {
        if (!auth.user.is_secretaire) {

            if (auth.user.is_patient) {
                next({
                    name: "patient.home",
                });
            } 
            else if (auth.user.is_admin) {
                next({
                    name: "admin.home",
                });
            } 
            else if (auth.user.is_doctor) {
                next({
                    name: "doctor.home",
                });
            } 
            else if(auth.user.is_intervenant) {
              next({
                name: "intervenant.home"
              });
            }
            else {
                next({
                    name: "home",
                });
            }
            return;
        }
    }
    // Middleware Admin
    if (to.matched.some((record) => record.meta.middlewareAdmin)) {
      
        if (!auth.user.is_admin) {

            if (auth.user.is_patient) {
                next({
                    name: "patient.home",
                });
            } 
            else if (auth.user.is_secretaire) {
                next({
                    name: "secretaire.home",
                });
            } 
            else if (auth.user.is_doctor) {
                next({
                    name: "doctor.home",
                });
            } 
            else if(auth.user.is_intervenant) {
              next({
                name: "intervenant.home"
              });
            }
            else {
                next({
                    name: "home",
                });
            }
            return;
        }
    }
    // Middleware Intervenant
    if(to.matched.some((record) => record.meta.middlewareIntervenant)) {
      if (!auth.user.is_intervenant) {

        if (auth.user.is_patient) {
            next({
                name: "patient.home",
            });
        } 
        else if (auth.user.is_secretaire) {
            next({
                name: "secretaire.home",
            });
        } 
        else if (auth.user.is_doctor) {
            next({
                name: "doctor.home",
            });
        } 
        else if (auth.user.is_admin) {
          next({
              name: "admin.home",
          });
        }
        else {
            next({
                name: "home",
            });
        }
        return;
    }
  }
  // Middleware Entreprise
  if(to.matched.some((record) => record.meta.middlewareEntreprise)) {
    if (!auth.user.is_entreprise) {

      if (auth.user.is_patient) {
          next({
              name: "patient.home",
          });
      } 
      else if (auth.user.is_secretaire) {
          next({
              name: "secretaire.home",
          });
      } 
      else if (auth.user.is_doctor) {
          next({
              name: "doctor.home",
          });
      } 
      else if (auth.user.is_admin) {
        next({
            name: "admin.home",
        });
      }
      else if(auth.user.is_intervenant) {
        next({
          name: "intervenant.home"
        });
      }
      else {
          next({
              name: "home",
          });
      }
      return;
  }
}
  next();
});


export default router;
