import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import Auth from './Auth/auth.js';
import Api from './Auth/api.js';
import CxltToastr from 'cxlt-vue2-toastr'
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'
import VueCookies from 'vue-cookies'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import { LMap, LTileLayer, LMarker, LControl } from 'vue2-leaflet'; 
import 'leaflet/dist/leaflet.css';
import VCalendar from 'v-calendar';

//window.api = new Api();
import './middlewares/axios-interceptors.js'

//import './plugins/pusher.js'


// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
                // ...other defaults
});
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-control', LControl);
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
Vue.use(VueTelInputVuetify, {
  vuetify,
});

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expires: '7d'})
var toastrConfigs = {
  position: 'top center',
  showDuration: 2000,
  timeOut: 4000
}
Vue.use(CxltToastr, toastrConfigs)
process.env.VUE_APP_ENV == 'local' ? axios.defaults.baseURL = 'http://localhost:8000/api/' : axios.defaults.baseURL = 'https://backend.sahelassistance.com/api/';
Vue.prototype.$api = new Api();
Vue.prototype.$http = axios;
Vue.prototype.$auth = new Auth();
Vue.config.productionTip = false

// new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App)
// }).$mount('#app') 
// axios.get('auth/check-maintenance')
//   .then(response => {
//     if (response.data.maintenance) {
//       router.push('/maintenance');
//     } 
//   })
//   .catch(() => {
//     // En cas d'erreur, afficher une page de maintenance
//     router.push('/maintenance');
// });

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')